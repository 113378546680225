const CATEGORIES: string[] = [
  'Primary Image',
  'Secondary Image',
  'Mini-Prop',
  'Mini-Prop Sticker',
  'Acquisition / Legal Documents',
  'Closing and Miscellaneous',
  'Financial',
  'Financing Matters',
  'Government',
  'Insurance',
  'Internal Procedures and Reporting',
  'Litigation',
  'Overview',
  'Personal Property Inventory',
  'Physical Property Inspection and Review',
  'Property Operations and Management',
  'Service Contracts',
  'Tenant/Lease',
  'Title/Survey/Zoning'
]

export default CATEGORIES
