<template>
  <div class="drop-n-upload-component">
    <b-field>
      <b-upload
        @input="onUpload"
        :disabled="!editable"
        multiple
        drag-drop
        accept="image/*, application/pdf, .doc, .docx, .xls, .xlsx">
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon
                icon="upload"
                size="is-large">
              </b-icon>
            </p>
            <p>Drop your files here or click to upload</p>
          </div>
        </section>
      </b-upload>
    </b-field>
    <ValidationObserver v-slot="{ valid }" class="is-block">
      <form id="upload-form" ref="uploadForm" class="mt-5">
        <div
          class="preview-item px-1 pt-4 pb-5 is-flex is-align-items-center"
          v-for="(file, index) in dropFiles"
          :key="index"
        >
          <div class="column-preview px-1 is-clickable" @click="onFilePreview({index, image: previewImages[`${file.name}`], name: file.name})">
            <img :src="previewImages[`${file.name}`] || placeholderImages.default" alt="file preview" class="preview-image">
          </div>
          <div class="column-flex">
            <ValidationProvider rules="required" class="column-name px-1 is-block mb-2">
              <p class="has-text-weight-semibold mb-1 pl-2 required input-label">File Name</p>
              <b-input v-model.trim="fileLabels[`${file.name}`]" :name="`${nameOfUploadModel}[${index}][file_label]`"></b-input>
            </ValidationProvider>
            <div class="is-flex is-align-items-end">
              <template v-if="forCorpDataRoom || forCorpExecutives">
                <ValidationProvider rules="required" class="column-category-tree px-1">
                  <p class="has-text-weight-semibold mb-1 pl-2 required input-label">Category</p>
                  <Treeselect
                    v-model="dataRoomFileCategory[`${file.name}`]"
                    :name="`${nameOfUploadModel}[${index}][file_category]`"
                    :options="fileCategories"
                    :disable-branch-nodes="true"
                    :show-count="true"
                    :searchable="true"
                    placeholder=" Select file category and type"
                    :disabled="fileCategories.length <= 1"
                  >
                    <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                      <span :title="node.label">{{ node.label }}</span>
                      <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
                    </label>
                  </Treeselect>
                  <input hidden :name="`${nameOfUploadModel}[${index}][file_category]`" :value="fileCategories[0].id" :disabled="fileCategories.length > 1" />
                </ValidationProvider>
                <ValidationProvider class="column-applicable-date px-1">
                  <p class="has-text-weight-semibold mb-1 pl-2 input-label">Applicable Date</p>
                  <b-datepicker
                    v-model="applicableDates[`${file.name}`]"
                    locale="en-CA"
                    :name="`${nameOfUploadModel}[${index}][applicable_date]`"
                    placeholder="YYYY-MM-DD"
                    icon="calendar-week"
                    class="no-pre-nor-next"
                    editable
                  ></b-datepicker>
                </ValidationProvider>
                <ValidationProvider class="column-notes px-1">
                  <p class="has-text-weight-semibold mb-1 pl-2 input-label">Notes</p>
                  <b-input v-model.trim="notes[`${file.name}`]" :name="`${nameOfUploadModel}[${index}][notes]`" placeholder="Notes..."></b-input>
                </ValidationProvider>
              </template>
              <template v-else>
                <ValidationProvider rules="required" class="column-date-received px-1">
                  <p class="has-text-weight-semibold mb-1 pl-2 required input-label">Date Received</p>
                  <b-datepicker
                    v-model="dateReceived[`${file.name}`]"
                    locale="en-CA"
                    :name="`${nameOfUploadModel}[${index}][date_received]`"
                    placeholder="YYYY-MM-DD"
                    icon="calendar-week"
                    class="no-pre-nor-next"
                    editable
                  ></b-datepicker>
                </ValidationProvider>
                <ValidationProvider rules="required" class="column-category px-1 is-relative">
                  <p class="has-text-weight-semibold mb-1 pl-2 required input-label">Category</p>
                  <b-select
                    placeholder="Select a category"
                    v-model="fileCategory[`${file.name}`]"
                    :name="`${nameOfUploadModel}[${index}][file_category]`"
                    @input="onCategorySelected(file.name)"
                  >
                    <option
                      v-for="category in categories"
                      :key="category"
                      :value="category"
                    >{{category}}</option>
                  </b-select>
                  <p class="help is-danger category-error" v-show="categoryErrors[`${file.name}`]">* Mini-Prop and Sticker must be SVG format</p>
                </ValidationProvider>
                <span class="column-received-from px-1">
                  <p class="has-text-weight-semibold mb-1 pl-2 input-label">Received From</p>
                  <b-input v-model.trim="receivedFrom[`${file.name}`]" :name="`${nameOfUploadModel}[${index}][received_from]`"></b-input>
                </span>
                <span class="column-confidential px-1 is-flex is-align-items-center">
                  <input
                    type="checkbox"
                    :id="`${nameOfUploadModel}[${index}][confidential]`"
                    :name="`${nameOfUploadModel}[${index}][confidential]`"
                    :value="confidential[file.name]"
                    @change="onCheckboxChange(file.name, $event)">
                  <label :for="`${nameOfUploadModel}[${index}][confidential]`" class="pl-2 checkbox-label has-text-weight-semibold">Confidential</label>
                </span>
              </template>
              <span class="px-1 column-action has-text-centered">
                <button class="button is-danger is-outlined button-remove" @click="onFileRemoval(index)">Remove</button>
              </span>
            </div>
          </div>
          <input
            v-if="forCorpDataRoom"
            type="hidden"
            :name="`${nameOfUploadModel}[${index}][corporate_department]`"
            :value="corporateDepartment">
        </div>
      </form>
      <div class="has-text-centered" v-if="!forCorpExecutives">
        <b-button
          v-show="dropFiles.length"
          type="is-blue"
          class="mt-5 px-6"
          :disabled="!valid || !isCategoryValid"
          @click="onSubmit"
        > SUBMIT </b-button>
      </div>
    </ValidationObserver>
    <FilePreviewModal :fileUrl="previewUrl" :fileType="previewFileType" @closeModal="onFilePreviewModalHide"></FilePreviewModal>
  </div>
</template>
<script>
import CATEGORIES from '@/utils/data/due_diligence_uploads_categories'
import { encodeFileAsUrl } from '@utils/common-methods/common'
import Treeselect from '@riophae/vue-treeselect'
import FilePreviewModal from '@components/modal/file-preview-modal.vue'
import fileTypeMixin from '@utils/mixins/file-type-mixin.js'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
const LOGOS = {
  word: 'https://addy-public.s3.us-west-2.amazonaws.com/word-logo.png',
  pdf: 'https://addy-public.s3.us-west-2.amazonaws.com/pdf-logo.png',
  excel: 'https://addy-public.s3.us-west-2.amazonaws.com/excel-logo.png',
  default: 'https://addy-public.s3.us-west-2.amazonaws.com/basic-file.png',
}

export default {
  components: {
    Treeselect,
    FilePreviewModal,
  },
  mixins: [fileTypeMixin],
  data() {
    return {
      categories: Object.freeze(CATEGORIES),
      placeholderImages: Object.freeze(LOGOS),
      dropFiles: [],
      fileLabels: {},
      applicableDates: {},
      notes: {},
      dateReceived: {},
      fileCategory: {},
      receivedFrom: {},
      confidential: {},
      previewImages: {},
      dataRoomFileCategory: {},
      previewUrl: '',
      previewFileType: 'image',
      categoryErrors: {},
      isCategoryValid: true,
    }
  },
  mounted() {
    this.$refs.uploadForm.addEventListener('submit', this.preventFormDefault)
  },
  beforeDestroy() {
    this.$refs.uploadForm.removeEventListener('submit', this.preventFormDefault)
  },
  props: {
    fileCategories: Array,
    feature: {
      type: String,
      required: true,
      validator: (value) => {
        return ['corpDataRoom', 'corpExecutives', 'propDueDiligence'].includes(value)
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
    corporateDepartment: {
      type: [String, undefined],
      validator: (value) => {
        return ['legal', 'finance', 'real_estate', undefined].includes(value)
      },
    },
  },
  computed: {
    forCorpDataRoom() {
      return this.feature === 'corpDataRoom'
    },
    forCorpExecutives() {
      return this.feature === 'corpExecutives'
    },
    nameOfUploadModel() {
      const map = {
        corpExecutives: 'corporation',
        corpDataRoom: 'corporation',
        propDueDiligence: 'property',
      }
      return map[this.feature]
    },
    hasUnsavedChanges() {
      return this.dropFiles.length > 0
    },
    prepopulateFileCategory() {
      return this.$route.query.category
    },
  },
  methods: {
    onUpload(files) {
      for (let index = 0; index < files.length; index++) {
        const fileName = files[index].name
        if (this.previewImages[fileName]) continue
        if (this.forCorpExecutives) {
          this.dataRoomFileCategory[fileName] = this.fileCategories[0].id
        } else if (this.prepopulateFileCategory) {
          this.dataRoomFileCategory[fileName] = this.prepopulateFileCategory
        }

        this.setPreviewImage(files[index]).then((url) => {this.$set(this.previewImages, fileName, url)})
        this.prepopulateFileLabel(fileName)
      }
      this.dropFiles = files
    },
    setPreviewImage(file) {
      const fileName = file.name

      return new Promise((resolve) => {
        if (this.isImage(fileName)) {
          encodeFileAsUrl(file, (url) => resolve(url))
        } else if (this.isDoc(fileName)) {
          resolve(LOGOS.word)
        } else if (this.isPdf(fileName)) {
          resolve(LOGOS.pdf)
        } else if (this.isExcel(fileName)) {
          resolve(LOGOS.excel)
        } else {
          resolve(LOGOS.default)
        }
      })
    },
    prepopulateFileLabel(fileName) {
      this.fileLabels[`${fileName}`] = fileName.replace(/\.\w+$/i, '')
    },
    onCheckboxChange(key, event) {
      this.$set(this.confidential, key, event.target.checked)
    },
    onFileRemoval(fileIndex) {
      this.dropFiles.splice(fileIndex, 1)
    },
    onSubmit() {
      const formData = new FormData(this.$refs.uploadForm)
      this.dropFiles.forEach((file, index) => formData.append(`${this.nameOfUploadModel}[${index}][file]`, file))
      this.$emit('onSubmit', formData)
    },
    preventFormDefault(event) {
      event.preventDefault()
    },
    /**
     * @param {String} image image url for preview
     * @param {String} name file name to get file type
     * @param {Number} index index of file in dropFiles, for pdf preview
     */
    onFilePreview({ image, name, index }) {
      this.previewFileType = this.getFileTypeFromName(name)

      if (this.previewFileType !== 'pdf') {
        this.previewUrl = image
        return
      }

      encodeFileAsUrl(this.dropFiles[index], (url) => {this.previewUrl = url})
    },
    onFilePreviewModalHide() {
      this.previewUrl = ''
    },
    onCategorySelected(fileName) {
      this.validateCategory(fileName, this.categoryValidationCallback)
    },
    validateCategory(fileName, callback) {
      this.categoryErrors[`${fileName}`] = this.validateMiniProp(fileName)
      callback()
    },
    categoryValidationCallback() {
      this.isCategoryValid = !Object.values(this.categoryErrors).some((bool) => bool)
    },
    validateMiniProp(fileName) {
      return this.fileCategory[`${fileName}`]?.includes('Mini-Prop') && !this.isSVG(fileName)
    },
    isSVG(fileName) {
      return /\.svg$/i.test(fileName)
    },
  },
}
</script>
<style lang="scss" scoped>
.upload.control {
  width: 100%;
  ::v-deep .upload-draggable {
    width: 100%;
  }
}
.preview-item {
  &:not(:last-of-type) {
    border-bottom: 1px dashed #e2e1e1;
  }
  .input-label {
    font-size: 16px;
    &.required::before {
      content: '* ';
      color: red;
    }
  }
  .column-preview {
    width: 5%;
    text-align: center;
    img {
      height: 40px;
      object-fit: contain;
      object-position: center;
    }
  }
  .column-flex {
    width: 95%;
    .column-category-tree {
      width: 40%;
    }
    .column-category,
    .column-applicable-date,
    .column-date-received {
      width: 20%;
      .control {
        ::v-deep .select {
          width: 100%;
          select {
            width: 100%;
          }
        }
      }
    }
    .column-category {
      width: 30%;
      .category-error {
        position: absolute;
        bottom: -16px;
        left: 4px;
      }
    }
    .column-received-from {
      width: 28%;
    }
    .column-confidential {
      width: 12%;
      height: 40px;
    }
    .column-notes {
      width: 30%;
    }
    .column-action {
      width: 10%;
      .button-remove {
        border-style: solid;
      }
    }
  }
}
#upload-form {
  .preview-item {
    .checkbox-label {
      font-size: 16px;
    }
    .vue-treeselect.vue-treeselect--single {
      font-size: 16px;
      ::v-deep .vue-treeselect__control {
        border-radius: 12px;
        height: 40px;
      }
    }
  }
}
</style>
