export default {
  methods: {
    isPdf(fileName) {
      return /\.(pdf)$/i.test(fileName)
    },
    isDoc(fileName) {
      return /\.(doc|docx)$/i.test(fileName)
    },
    isExcel(fileName) {
      return /\.(xls|xlsx)$/i.test(fileName)
    },
    isImage(fileName) {
      return /\.(jpe?g|png|gif)$/i.test(fileName)
    },
    getFileTypeFromName(fileName) {
      // types: 'image', 'pdf', 'xls', 'doc'
      if (this.isImage(fileName)) {
        return 'image'
      } else if (this.isDoc(fileName)) {
        return 'doc'
      } else if (this.isPdf(fileName)) {
        return 'pdf'
      } else if (this.isExcel(fileName)) {
        return 'xls'
      } else {
        return 'image'
      }
    },
  },
}
