<template>
  <div :class="`modal file-preview-modal ${isActive ? 'is-active' : ''}`">
    <div class="modal-background"></div>
    <div class="modal-content is-flex is-justify-content-center is-align-items-center" @click="closeModal">
      <iframe v-if="useActualFile" :src="fileUrl" frameborder="0" class="file-container"></iframe>
      <img v-else :src="dynamicImageUrl" alt="file preview" class="preview-image">
    </div>
    <button class="modal-close is-large" aria-label="close" @click="closeModal"></button>
  </div>
</template>

<script>
const PREVIEW = {
  word: 'https://addy-public.s3.us-west-2.amazonaws.com/word-logo.png',
  excel: 'https://addy-public.s3.us-west-2.amazonaws.com/excel-logo.png',
  default: 'https://addy-public.s3.us-west-2.amazonaws.com/basic-file.png',
}

export default {
  data() {
    return {
      isActive: false,
    }
  },
  props: {
    fileUrl: {
      type: String,
      default: '',
    },
    // use type to determine preview image or preview the actual file
    fileType: {
      type: String,
      required: true,
      validator(type) {
        return ['image', 'pdf', 'xls', 'doc'].includes(type)
      },
    }
  },
  computed: {
    useActualFile() {
      return this.fileType === 'pdf'
    },
    dynamicImageUrl() {
      if (this.fileType === 'image') return this.fileUrl
      else if (this.fileType === 'doc') return PREVIEW.word
      else if (this.fileType === 'xls') return PREVIEW.excel
      else return PREVIEW.default
    },
  },
  created() {
    this.isActive = !!this.fileUrl
  },
  watch: {
    fileUrl(newValue) {
      this.isActive = !!newValue
    }
  },
  methods: {
    closeModal() {
      this.isActive = false
      this.$emit('closeModal')
    },
  },
}
</script>
<style lang="scss" scoped>
.modal-content {
  height: 100%;
  .file-container {
    width: 80%;
    height: 100%;
  }
  .preview-image {
    object-fit: contain;
    max-width: 80%;
    max-height: 80%;
  }
}
</style>
